<template>
  <div id="matrixZH" class="bg-grey-color">

    <div class="flex-content">
      <div style="height:280px;width:100%;overflow:hidden;">
        <img style="height:280px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/h5/rail.jpeg">
      </div>
      <div style="position: absolute; bottom:60px;">
        <div class="flex-column-center">
          <div class="specialHeaderImg">
            <nut-avatar size="88"
                        icon="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/common/crrc-ico.png"
            ></nut-avatar>
          </div>
          <div style="margin-top:20px;">
            <span style="font-size:28px;">中凰文旅微信矩阵</span>
          </div>
        </div>
      </div>

    </div>

    <nut-divider dashed :style="{ color: '#2c3e50', padding: '0 16px' }">上级公司</nut-divider>

    <div style="min-height:140px;">
      <div class="flex-row-center">
        <div class="flex-column-center gzs" @click="jumpZCJT">
          <div class="specialImg">
            <nut-avatar icon="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/common/crrc-ico.png"></nut-avatar>
          </div>
          <div style="margin-top:4px;">
            <span class="fs-14">中车交通</span>
          </div>
        </div>

      </div>
    </div>


    <nut-divider dashed :style="{ color: '#2c3e50', padding: '0 16px' }">投资参股</nut-divider>

    <div class="flex-row-center">
      <div class="flex-column-center gzs" @click="jumpSHZTD">
        <div class="">
          <nut-avatar icon="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/common/SHZTD.jpeg"></nut-avatar>
        </div>
        <div>
          <span class="fs-14">上海中达通</span>
        </div>
      </div>

      <div class="flex-column-center gzs" @click="jumpJXZCTD">
        <div class="specialNormalImg">
          <nut-avatar icon="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/common/JXZCTD.jpeg"></nut-avatar>
        </div>
        <div>
          <span class="fs-14">中城通达</span>
        </div>
      </div>

      <div class="flex-column-center gzs" @click="jumpSZZCTD">
        <div class="specialImg">
          <nut-avatar icon="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/common/crrc-ico.png"></nut-avatar>
        </div>
        <div>
          <span class="fs-14">中车通达</span>
        </div>
      </div>

      <div class="flex-column-center gzs" @click="jumpXLT">
        <div class="">
          <nut-avatar icon="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/common/lanting.jpeg"></nut-avatar>
        </div>
        <div>
          <span class="fs-14">小蓝亭</span>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "MatrixZH",
  setup() {
    const jumpSZZCTD = () => {
      location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkxNzM0MDE3NQ==&scene=124#wechat_redirect';
    };

    const jumpXLT = () => {
      location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI0Mzg5NDMzMQ==&scene=124#wechat_redirect';
    };

    const jumpZCJT = () => {
      location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI0MjM2MjI3Ng==&scene=124#wechat_redirect';
    };

    const jumpSHZTD = () => {
      location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU0MTc2MzIxNg==&scene=124#wechat_redirect';
    };

    const jumpJXZCTD = () => {
      location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0NTE1NzMzNw==&scene=124#wechat_redirect';
    };

    return {
      jumpSZZCTD,
      jumpXLT,
      jumpZCJT,
      jumpSHZTD,
      jumpJXZCTD
    };
  }

}
</script>

<style scoped>
#matrixZH {
  font-family: serif, Helvetica, Arial, sans-serif;
  min-height: 100vh;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:0 10px;
}

.gzs {
  flex: 0 0 20%;
  background-color: #fff;
  padding: 26px 4px;
  margin-right: calc(10% / 3);
  border-radius: 6px;
}

/* 去除每行尾的多余边距 */
.gzs:nth-child(4n){
  margin-right: 0;
}
/* 使最后一个元素的边距填满剩余空间 */
.gzs:last-child{
  margin-right: auto;
}

.flex-content {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

</style>

<style>
.specialHeaderImg .nut-avatar .nut-icon__img {
  width: 65% !important;
}

.specialImg .nut-avatar .nut-icon__img {
  width: 60% !important;
}

.specialImg .nut-avatar, .specialNormalImg .nut-avatar {
  background-color: #fff !important;
  border: 1px solid #f9d2d2;
}

.specialHeaderImg .nut-avatar {
  background-color: #fff !important;
}

.nut-divider:before, .nut-divider:after {
  border: 29px dashed #c29643 !important;
  border-width: 6px 0 0 !important;
}
</style>
